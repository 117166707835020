export const messages: Record<string, string> = {
    i0001: "Error occurred.",
    i0002: "Cancel",
    i0003: "Confirm",
    i0004: "Dashboard",
    i0005: "Edit Scenario",
    i0006: "Scenario Flow",
    i0007: "Scenario Templates",
    i0008: "Conversation History",
    i0009: "WebChat Integrations",
    i0010: "Scenario Settings",
    i0011: "Scenario title is required.",
    i0012: "Enter a scenario title less than 50 characters.",
    i0013: "Welcome Scene ID is required.",
    i0014: "Scene ID is a numeric value.",
    i0015: "Fallback Scene ID is required.",
    i0016: "Latency is a numeric value.",
    i0017: "Error occurred while loading scene information.",
    i0018: "Error occurred while loading scenario information.",
    i0019: "Error occurred while loading NLU Agent.",
    i0020: "Select a scenario language.",
    i0021: "Go to the previous",
    i0022: "Go to the welcome message",
    i0023: "Error occurred.",
    i0024: "Scenario information is changed.",
    i0025: "Error occurred while registering scenario.",
    i0026: "Wrong input is found.",
    i0027: "Error occurred while deleting scenario.",
    i0028: "Basic information",
    i0029: "Title",
    i0030: "Enter a scenario title.",
    i0031: "Description",
    i0032: "Enter a scenario description.",
    i0033: "Welcome Scene ID",
    i0034: "No scenes match",
    i0035: "Enter welcome Scene ID.",
    i0036: "Fallback Scene ID",
    i0037: "Enter fallback Scene ID.",
    i0038: "second",
    i0039: "Set Paragraph API latency.",
    i0040: "Select a Scenario NLU Agent.",
    i0041: "Not selected",
    i0042: "Auto complete",
    i0043: "Select auto complete settings.",
    i0044: "Error messages",
    i0045: "Enter a default error message.",
    i0046: "Use the stack function(go to the previous, go to the start).",
    i0047: "Go to the previous button",
    i0048: "Go to the previous",
    i0049: "Go to the start button",
    i0050: "Go to the start",
    i0051: "View debug messages",
    i0052: "Language",
    i0053: "Korean",
    i0054: "Use Google Analytics",
    i0055: "Save",
    i0056: "Scenario constants settings",
    i0057: "Key",
    i0058: "Value",
    i0059: "Management",
    i0060: "View",
    i0061: "Copy",
    i0062: "Reissue",
    i0063: "Delete a scenario",
    i0064: "Scenario cannot be restored after deletion. Please think carefully.",
    i0065: "Data cannot be restored after deletion.",
    i0066: "Close",
    i0067: "Delete",
    i0068: "Error occurred while loading.",
    i0069: "Register Scene",
    i0070: "Wrong format",
    i0071: "Error occurred while modifying.",
    i0072: "Add",
    i0073: "Basic information",
    i0074: "Enter a title.",
    i0075: "Templates",
    i0076: "Types",
    i0077: "Select types.",
    i0078: "Data types",
    i0079: "Select data types.",
    i0080: "Settings",
    i0081: "Title is required.",
    i0082: "Enter a title less than 50 characters.",
    i0083: "Error occurred while registering.",
    i0084: "Do you really want to delete {name}?\nData cannot be restored after deletion.",
    i0085: "Error occurred while deleting.",
    i0086: "Type is required.",
    i0087: "Data type is required.",
    i0088: "Data is required.",
    i0089: "Valid user",
    i0090: "Valid conversation",
    i0091: "Average conversation time",
    i0092: "All messages",
    i0093: "User messages",
    i0094: "Bot messages",
    i0095: "Satisfied",
    i0096: "Dissatisfied",
    i0097: "Initialization",
    i0098: "User",
    i0099: "Conversation",
    i0100: "Valid use amount per day",
    i0101: "Messages per day",
    i0102: "Message feedback",
    i0103: "User conversation flow",
    i0104: "No data are found.",
    i0105: "Error occurred while loading the scenario list.",
    i0106: "Register a scenario",
    i0107: "Scenario List",
    i0108: "Execute conditions",
    i0109: "Next Scene conditions",
    i0110: "Set as a template",
    i0111: "Wrong entry condition format.",
    i0112: "Wrong execute condition format.",
    i0113: "Drag to move",
    i0114: "Down",
    i0115: "Up",
    i0116: "To the bottom",
    i0117: "To the top",
    i0118: "Duplicate",
    i0119: "Set as a template",
    i0120: "Bulk scenario test",
    i0121: "Import a scenario Excel file",
    i0122: "Export scenario as an Excel file",
    i0123: "Search by title, description, data and etc. on the target Scene or Paragraph.",
    i0124: "Run WebChat",
    i0125: "Conversation test",
    i0126: "Enter Scene ID.",
    i0127: "Entry Conditions",
    i0128: "Last Paragraph",
    i0129: "Import a scenario",
    i0130: "Drag-and-drop a scenario Excel file.",
    i0131: "Import",
    i0132: "Scenario Bulk Test",
    i0133: "Drag-and-drop a test case Excel file here.",
    i0134: "Scene ID is required.",
    i0135: "Enter Scene ID less than 20 characters.",
    i0136: "Welcome Scene",
    i0137: "Fallback Scene",
    i0138: "Set as welcome Scene",
    i0139: "Set as fallback Scene",
    i0140: "Scene ID to move",
    i0141: "Send a text by clicking the button",
    i0142: "Go to the next by clicking the button",
    i0143: "Open the link by clicking the button",
    i0144: "Button name",
    i0145: "Select action",
    i0146: "Mobile Link(default)",
    i0147: "PC Link",
    i0148: "Set variables with clicking the button",
    i0149: "Data to send with the message transmission",
    i0150: "Button settings",
    i0151: "Card settings",
    i0152: "Enter a title.",
    i0153: "Enter a text.",
    i0154: "Enter the Link URL for mobile.",
    i0155: "Enter the Link URL for PC.",
    i0156: "Media types",
    i0157: "Enter the URL of the selected media type.",
    i0158: "Conditions",
    i0159: "Add conditions",
    i0160: "Enter API URL.",
    i0161: "Select HTTP Method.",
    i0162: "Header Key",
    i0163: "Header Value",
    i0164: "Parameter settings",
    i0165: "User input message",
    i0166: "Intent name",
    i0167: "Entity name or array",
    i0168: "0 means there is no paragraph corresponding to the execute conditions.",
    i0169: "The number of consecutive intent duplications",
    i0170: "The number of consecutive intent classification failures",
    i0171: "Variable input required",
    i0172: "String input required. Remove punctuations when the input is numeral.",
    i0173: "String array",
    i0174: "Regex matched. source: regex, target: value",
    i0175: "Regex not matched. source: regex, target: value",
    i0176: "included in the target",
    i0177: "not included in the target",
    i0178: "start(s) with the source",
    i0179: "end(s) with the source",
    i0180: "exist(s)",
    i0181: "not exist(s)",
    i0182: "Select a range",
    i0183: "Enter the key value of the variable.",
    i0184: "Enter the value to save as a variable.",
    i0185: "Error occurred while creating an account.",
    i0186: "Email address is required.",
    i0187: "Enter email address at least 6 and less than 50 characters.",
    i0188: "Enter a valid email address.",
    i0189: "Enter a name at least 1 and less than 20 characters.",
    i0190: "Password is required.",
    i0191: "Enter a password at least 6 and less than 12 characters.",
    i0192: "Error occurred while signing-in.",
    i0193: "Bot",
    i0194: "All",
    i0195: "Error occurred while loading the message list.",
    i0196: "Error occurred while loading conversation history.",
    i0197: "Enter the text of the message you are looking for.",
    i0198: "Chat window",
    i0199: "Export as an Excel file",
    i0200: "User input",
    i0201: "Chatbot response",
    i0202: "Input time",
    i0203: "User key",
    i0204: "Per user",
    i0205: "Per conversation",
    i0206: "Valid",
    i0207: "Satisfied",
    i0208: "Dissatisfied",
    i0209: "Error occurred while resetting password.",
    i0210: "Email address is required.",
    i0211: "Enter an email address at least 6 and less than 50 characters.",
    i0212: "Enter a valid email address.",
    i0213: "WebChat Integration Settings",
    i0214: "Scenario History",
    i0215: "Deploy Scenario",
    i0216: "Version is required.",
    i0217: "Deploy",
    i0218: "Add a template",
    i0219: "Version is required.",
    i0220: "Enter a description.",
    i0221: "Version",
    i0222: "Deploy this version",
    i0223: "Redeploy",
    i0224: "Deploy this scenario as version {version}. Current scenario will be disabled.",
    i0225: "Rollback Scenario",
    i0226: "Rollback this scenario to before the change(s)",
    i0227: "Rollback",
    i0228: "View Changes",
    i0229: "No Changes",
    i0230: "Rollback to before this change",
    i0231: "Change Scenario",
    i0232: "Change Scenario Settings",
    i0233: "Change Scenario Templates",
    i0234: "View Differences",
    i0235: "On Service",
    i0236: "The same scenario is already deployed.",
    i0237: "There is no changes from current scenario.",
    i0238: "Variable Settings",
    i0239: "Carousel Settings",
    i0240: "Media Settings",
    i0241: "Message Settings",
    i0242: "Show a random message if there are multiple messages",
    i0243: "Custom Data Settings",
    i0244: "API Settings",
    i0245: "Code Settings",
    i0246: "Scenario Template Settings",
    i0247: "Scenario Template List",
    i0248: "Scene List",
    i0249: "Default",
    i0250: "Custom ID",
    i0251: "ID",
    i0252: "Priority",
    i0253: "Title",
    i0254: "Ascending",
    i0255: "Descending",
    i0256: "Scene Settings",
    i0257: "Priority",
    i0258: "Priority (in number 0-9999)",
    i0259: "Paragraph Settings",
    i0260: "Add a Paragraph",
    i0261: "Other Settings",
    i0262: "Scenario Action Settings",
    i0263: "Variable Lifespan Settings",
    i0264: "-1: no reset, 0: null, positive integer: maintain within the number",
    i0265: "Chat(Experimental)",
    i0266: "Download as PNG",
    i0267: "Start Bulk Test",
    i0268: "Download as Excel file",
    i0269: "Text when user select the button",
    i0270: "Delete a Scene",
    i0271: "Add a Scene",
    i0272: "Search",
    i0273: "Clear",
    i0274: "Select a NLU Service",
    i0275: "개발중",
    i0276: "버튼",
    i0277: "퀵리플라이",
    i0278: "한국어",
    i0279:
        "WebChat 화면을 설정합니다. 수정 내용은 수정 즉시 반영되며, 챗봇을 사용중인 경우 새로고침해야 변경사항을 확인할 수 있습니다.",
    i0280: "상담 시작",
    i0281: "상담 종료",
    i0282: "메시지를 입력하세요",
    i0283:
        "활성화하면 테스트용 WebChat에서 디버그 메시지를 확인할 수 있습니다.\n- 테스트용 WebChat : 시나리오 편집, 챗봇 화면 설정",
    i0284: "활성화 후 Google Analytics Tracker ID를 입력하면 해당 Tracker ID로 챗봇 이벤트를 전송합니다.",
    i0285: "챗봇이 동작하면서 발생하는 이벤트(대화 메시지 포함)를 설정한 URL로 전송합니다.",
    i0286:
        "시나리오가 동작하는 동안 {{ constant }}와 같이 사용할 수 있는 값을 설정합니다.\n상수명과 동일한 변수를 시나리오 내부에서 사용하는 경우 상수 값은 변수 값으로 대체됩니다.",
    i0287: "설정",
    i0288:
        "Dialogflow > Settings > General > Service Account 클릭 > JSON 키 생성 후 다운받은 JSON 파일의 내용을 복사 & 붙여넣기 해주세요",
    i0289: "챗봇 처음 접속 시 노출할 씬을 설정합니다",
    i0290: "챗봇이 다음 응답 메시지를 찾지 못한 경우 노출할 씬을 설정합니다",
    i0291: "Utility > API 패러그래프 사용 시 API로 부터의 응답 대기 시간을 설정합니다",
    i0292: "챗봇에서 다음 응답을 찾는 도중 오류가 발생했을 때 노출할 메시지를 설정합니다.",
    i0293:
        "챗봇 동작 중 이전 단계로 이동하거나 처음으로 이동하는 기능을 사용하려면 스택 기능을 활성화 합니다.\n이전 단계로 이동 버튼과 처음으로 이동 버튼은 시나리오에서 수동으로 설정해야합니다.",
    i0294: "동일한 템플릿을 여러 씬에서 사용하는 경우 템플릿으로 지정하여 재사용할 수 있습니다.",
    i0295:
        "시나리오에 명시적으로 설정된 다음 이동 조건으로 흐름을 10단계까지 보여줍니다.\n- 배경 클릭 : 전체 연결 보기/숨기기\n- 노드 클릭 : 해당 노드 연결 보기/숨기기\n- 실선 : 버튼으로 연결\n- 점선 : 텍스트 입력 조건으로 연결",
    i0296: "다음 연결 있음",
    i0297: "진입 경로 없음",
    i0298: "다음 연결 없음",
    i0299: "챗봇 사용 통계를 제공합니다.",
    i0300:
        "다음 화면에서 수정 시 운영중인 챗봇에 반영하기 위해 배포가 필요합니다.\n- 시나리오 편집, 시나리오 템플릿, 시나리오 설정",
    i0301: "- deploy: 배포\n- restore: 이전 배포에서 되돌림",
    i0302: "현재 작성되어있는 시나리오와의 차이점을 확인할 수 있습니다.",
    i0303: "운영중인 챗봇을 해당 배포 버전으로 되돌립니다.",
    i0304: "씬을 구분할 수 있는 ID를 설정합니다. ID가 중복되는 경우 챗봇이 의도한대로 동작하지 않을 수 있습니다.",
    i0305:
        "사용자 쿼리에 대한 응답을 찾을 때 진입조건에 매칭되는 씬이 여러개 있는 경우 우선순위가 높은 씬을 답변으로 제공합니다.\n0부터 9999까지의 숫자로 클수록 우선순위가 높습니다.",
    i0306: "챗봇 시나리오를 작성하는 화면입니다. 시나리오 > 씬 > 패러그래프로 구성됩니다.",
    i0307: "서버와 연결이 끊겼습니다",
    i0308:
        "테스트 케이스 엑셀 파일을 업로드하여 여러개의 쿼리를 한번에 테스트 하고 결과를 확인할 수 있습니다.\n- 테스트 케이스 엑셀 파일 : 엑셀 파일(.xlsx), 1열에 사용자 입력 쿼리",
    i0309: "시나리오를 누가 언제 어디서 어떤 내용을 수정했는지 확인할 수 있습니다.(최대 50개)",
    i0310:
        "- scenario_ui: 시나리오 편집 화면\n- scenario_setting_ui: 시나리오 설정 화면\n- paragraph_template_ui: 시나리오 템플릿 화면\n- rollback: 시나리오 롤백\n- publisher_ui: 챗봇 연동 설정 화면",
    i0311: "시나리오 변경 사항을 확인하고 해당 변경사항이 적용되기 이전으로 시나리오를 롤백할 수 있습니다.",
    i0312: "챗봇 사용자의 IP 주소",
    i0313: "챗봇 사용자 키값으로 챗봇 채널마다 형식이 다를 수 있습니다.\n- WebChat: 32자 랜덤 값",
    i0314:
        "챗봇 사용자가 대화를 진행한 채널입니다.\n- WebChat, 카카오톡 외 커스터마이징 된 웹이나 사이트가 될 수 있습니다.",
    i0315: "챗봇이 응답한 횟수입니다. Welcome이 1이고 이후 응답은 2부터 시작합니다",
    i0316: "피드백 수집 기능을 사용하는 경우 해당 챗봇 응답에 대한 피드백을 확인할 수 있습니다.\n- good or bad",
    i0317: "@엔티티 이름|엔티티 값",
    i0318: "시스템에서 관리하는 씬의 ID 입니다.",
    i0319: "시나리오 편집 > 씬에서 작성한 ID 입니다.",
    i0320: "챗봇 응답이 어떤 패러그래프로 구성되어있는지 확인할 수 있습니다.",
    i0321:
        "- 전체 메시지 : 사용자 입력 쿼리와 챗봇 응답\n- 사용자별 : 사용자 기준 대화 이력\n- 대화별 : 대화 기준 대화 이력(한 사용자는 여러개의 대화를 진행할 수 있음)",
    i0322: "File Attachement",
    i0323: "테스트 케이스의 테스트 갯수",
    i0324: "테스트 수행 시간(분:초)",
    i0325: "테스트 상태\n- progress: 진행중\n- done: 완료",
    i0326: "테스트 케이스 다운로드",
    i0327: "테스트 결과 다운로드",
    i0328: "Minimize",
    i0329: "Maximize",
    i0330: "Minimize All",
    i0331: "Maximize All",
    i0332:
        "- 패러그래프를 선택(Ctrl(Cmd) + Click)하여 여러개의 패러그래프 순서를 변경할 수 있습니다.\n- 마우스 오른쪽 버튼을 누르면 메뉴를 사용할 수 있습니다.",
    i0333: "Output",
    i0334: "Utility",
    i0335: "Template",
    i0336: "Input",
    i0337: "Next",
    i0338: "Text",
    i0339: "Carousel",
    i0340: "Media",
    i0341: "Custom",
    i0342: "List",
    i0343: "Quick Reply",
    i0344: "Text",
    i0345: "Variable",
    i0346: "Python Code",
    i0347: "API",
    i0348: "Next",
    i0349: "Template",
    i0350: "사용자에게 지정한 형식의 메시지를 노출합니다.",
    i0351: "챗봇 동작에 필요한 값을 설정합니다.",
    i0352: "미리 정의한 템플릿을 사용합니다.",
    i0353: "사용자의 입력을 기다립니다.",
    i0354: "다음에 바로 연결되는 씬을 설정합니다.",
    i0355: "텍스트 형식의 메시지입니다.(여러개 설정하는 경우 랜덤으로 하나의 문구가 노출됩니다.)",
    i0356: "카드 형태로 구성되어있는 캐러셀 형식의 메시지 입니다.",
    i0357: "미디어 형식의 메시지 입니다. 이미지, 비디오, 오디오를 설정할 수 있습니다.",
    i0358: "JSON 형식으로 커스터마이징 된 응답 형태로 활용할 수 있습니다.",
    i0359: "리스트 형태로 버튼을 제공합니다.",
    i0360: "칩 형태로 버튼을 제공합니다.",
    i0361: "텍스트 입력을 대기합니다.",
    i0362:
        "변수를 설정할 수 있습니다. 여기서 설정한 key는 {example}와 같이 챗봇 응답이나 조건 등에 사용할 수 있습니다.",
    i0363: "파이썬 코드를 실행 후 결과를 변수로 저장할 수 있습니다.",
    i0364: "외부 API를 호출하여 결과를 활용할 수 있습니다. {example}",
    i0365: "Next의 데이터 유형은 변경할 수 없습니다",
    i0366: "Template의 데이터 유형은 변경할 수 없습니다",
    i0367: "{target} 템플릿에 선택하신 패러그래프가 추가되었습니다",
    i0368: "템플릿에 추가",
    i0369: "{count}개의 패러그래프",
    i0370: "패러그래프",
    i0371: "템플릿 삭제",
    i0372: "Webchat Integration Settings",
    i0373: "WebChat General Settings",
    i0374: "WebChat Title",
    i0375: "WebChat Title text",
    i0376: "WebChat Title image URL (Top priority)",
    i0377: "WebChat Image URL",
    i0378: "Chat Input field Placeholder",
    i0379: "Align button text",
    i0380: "Left",
    i0381: "Center",
    i0382: "Right",
    i0383: "Align carousel button text",
    i0384: "Show media(images, videos and audios) outside the bubble",
    i0385: "Show avatar",
    i0386: "Avatar position",
    i0387: "Top",
    i0388: "Bottom",
    i0389: "Avatar image URL",
    i0390: "Avatar size",
    i0391: "Round avatar shape",
    i0392: "Show close button",
    i0393: "Use auto complete",
    i0394: "Use feedback collecting",
    i0395: "Feedback collecting message",
    i0396: "Was the answer helpful?",
    i0397: "Box background",
    i0398: "Message font",
    i0399: "Good button",
    i0400: "Good button active",
    i0401: "Use view more text",
    i0402: "The number of lines to display",
    i0403: "Color settings",
    i0404: "Chat window background",
    i0405: "Background",
    i0406: "Font",
    i0407: "PlaceHolder",
    i0408: "Send button",
    i0409: "Title font",
    i0410: "Title background start",
    i0411: "Close button",
    i0412: "Font",
    i0413: "Background",
    i0414: "Font",
    i0415: "List button font",
    i0416: "List button mouse-over",
    i0417: "List button background",
    i0418: "Button font",
    i0419: "Button background",
    i0420: "Button font",
    i0421: "Button background",
    i0422: "Arrows",
    i0423: "Font",
    i0424: "Background",
    i0425: "Save",
    i0426: "Preview",
    i0427: "Web Page Embedding Banner Settings",
    i0428: "Button Settings",
    i0429: "Chat Window Settings",
    i0430: "JavaScript code for embedding",
    i0431: "Copy to clipboard",
    i0432: "Language Settings",
    i0433: "Under construction button font",
    i0434: "Under construction button background",
    i0435: "Under construnction button font",
    i0436: "Under construnction button background",
    i0437: "Message display latency(seconds)",
    i0438: "Number of autocompletes",
    i0439: "WebChat Description",
    i0440: "Name font",
    i0441: "Title background end",
    i0442: "Button border",
    i0443: "Preparing button border",
    i0444: "Button border",
    i0445: "Under construnction button border",
    i0446: "Font",
    i0447: "Background",
    i0448: "View more Text",
    i0449: "Highlight",
    i0450: "Bad button",
    i0451: "Bad button active",
    i0452: "Button mouse-over",
    i0453: "Border",
    i0454: "Border",
    i0455: "Border",
    i0456: "List button divider",
    i0457: "Confirm button font",
    i0458: "Confirm button background",
    i0459: "Confirm button border",
    i0460: "WebChat Advance Settings",
    i0461: "General",
    i0462: "Chat Input Field",
    i0463: "Time",
    i0464: "Avatar",
    i0465: "Autocomplete",
    i0466: "View more",
    i0467: "Feedback",
    i0468: "Quick Reply",
    i0469: "Bot Bubble Text Type",
    i0470: "Bot Bubble Carousel Type",
    i0471: "User’s Speech Bubble",
    i0472: "Attach a file",
    i0473: "Banner Settings",
    i0474: "Banner Text",
    i0475: "Banner font",
    i0476: "Background color start",
    i0477: "Background color end",
    i0478: "Dim background on chat window open",
    i0479: "Open chat window on load",
    i0480: "Banner preview",
    i0481: "If banner button shows under other element, increase z-index number.",
    i0482: "카카오톡 연동",
    i0483: "웰컴 스킬 URL",
    i0484: "폴백 스킬 URL",
    i0485: "카카오톡 오픈빌더 연동 방법",
    i0486: "챗봇 연동 설정 변경",
    i0487: "접속 사용자(실사용자)",
    i0488: "접속 세션(실세션)",
    i0489: "전체 메시지(사용자 메시지)",
    i0490: "세션당 평균 사용자 메시지",
    i0491:
        "사용자가 메시지를 입력 시 이 씬에 진입하도록 하는 조건을 설정합니다.\nand, or (, ), ==, != 등의 조건문을 사용할 수 있습니다.\n? 입력: 다음입력 예시\n# 입력: 인텐트 목록\n@ 입력: 엔티티 목록\n$ 입력: 사용가능한 변수, 상수 목록",
    i0492:
        "이 씬에 진입했을때 패러그래프를 수행하는 조건을 설정합니다.\nand, or (, ), ==, != 등의 조건문을 사용할 수 있습니다.\n? 입력: 다음입력 예시\n# 입력: 인텐트 목록\n@ 입력: 엔티티 목록\n$ 입력: 사용가능한 변수, 상수 목록",
    i0493: "표시할 항목이 없습니다. 시나리오 설정 > NLU 설정 > 42Maru NLU 설정을 확인해주세요",
    i0494: "ID는 유일한 값이어야 합니다",
    i0495: "ID는 필수 입력값입니다",
    i0496: "데이터 유형을 선택해주세요",
    i0497: "변수를 추가해주세요",
    i0498: "변수의 범위를 선택해주세요",
    i0499: "변수의 키를 입력해주세요",
    i0500: "변수의 값을 입력해주세요",
    i0501: "미디어 타입을 선택해주세요",
    i0502: "미디어의 URL을 입력해주세요",
    i0503: "올바른 URL을 입력해주세요",
    i0504: "카드를 추가해주세요",
    i0505: "미디어, 타이틀, 본문 중 한가지는 필수로 입력해야합니다",
    i0506: "버튼을 추가해주세요",
    i0507: "버튼 라벨을 입력해주세요",
    i0508: "버튼의 동작을 선택해주세요",
    i0509: "이동할 씬을 선택해주세요",
    i0510: "모바일 링크는 필수입니다",
    i0511: "이름은 필수 입력값 입니다",
    i0512: "이동할 씬을 선택해주세요",
    i0513: "API URL을 입력해주세요",
    i0514: "HTTP Method를 선택해주세요",
    i0515: "파라미터 키를 입력해주세요",
    i0516: "파라미터 값을 입력해주세요",
    i0517: "헤더 키를 입력해주세요",
    i0518: "헤더 값을 입력해주세요",
    i0519: "다음 씬 이동 조건을 추가해주세요",
    i0520: "조건을 입력해주세요",
    i0521: "이동할 씬을 선택해주세요",
    i0522: "커스텀 데이터는 올바른 JSON 형식이어야 합니다",
    i0523: "텍스트를 입력해주세요",
    i0524: "웹 브라우저에서 동작하는 챗봇을 설정할 수 있습니다.",
    i0525: "카카오톡",
    i0526: "카카오톡 오픈빌더와 연동하여 카카오톡으로 챗봇을 서비스할 수 있습니다.",
    i0527: "카카오톡 연동 활성화",
    i0528: "카카오톡과 챗봇을 연동하려면 체크하세요. 체크를 해제하면 카카오톡에서 챗봇이 동작하지 않습니다.",
    i0529: "시작",
    i0530: "카카오톡 오픈빌더",
    i0531: "카카오톡 오픈빌더에 접속 후 카카오톡 챗봇을 생성합니다.",
    i0532: "스킬 설정",
    i0533:
        "스킬 탭을 선택하여 웰컴 스킬, 폴백 스킬을 추가합니다.\n웰컴 스킬 URL과 폴백 스킬 URL을 복사하여 붙여넣기 하세요.",
    i0534: "블럭 설정",
    i0535: "시나리오 탭을 선택하여 웰컴 블록을 선택 후 웰컴 스킬을 설정합니다.",
    i0536: "웰컴 블록과 마찬가지로 폴백 블록을 선택 후 폴백 스킬을 설정합니다.",
    i0537: "채널 연결",
    i0538:
        "설정 탭을 선택하여 카카오톡 채널을 연결합니다.\n카카오톡 채널은 카카오톡채널 관리자 센터에서 관리하실 수 있습니다.",
    i0539: "카카오톡채널 관리자 센터",
    i0540: "배포",
    i0541: "배포 탭을 선택하신 후 배포를 진행하시면 카카오톡 채널에서 챗봇 동작을 확인하실 수 있습니다.",
    i0542: "변경사항이 있습니다. 변경사항을 취소하고 다른 페이지로 이동할까요?",
    i0543: "시나리오 배포",
    i0544: "시나리오 복원",
    i0545: "시간",
    i0546: "실행한 사람",
    i0547: "구분",
    i0548: "버전",
    i0549: "설명",
    i0550: "차이점",
    i0551: "진행중",
    i0552: "완료",
    i0553: "테스트 케이스 총 개수",
    i0554: "테스트 수행시간",
    i0555: "상태",
    i0556: "테스트 케이스",
    i0557: "테스트 결과",
    i0558: "시나리오 편집",
    i0559: "시나리오 설정",
    i0560: "시나리오 템플릿",
    i0561: "변경 전으로 되돌리기",
    i0562: "챗봇 연동 설정",
    i0563: "수정 위치",
    i0564: "변경점",
    i0565: "You don't have permission.",
    i0566: "Go",
    i0567: "Create a Scenario",
    i0568: "Owner",
    i0569: "ID는 200자 이하여야 합니다",
    i0570: "이름은 200자 이하여야 합니다",
    i0571: "템플릿을 선택해주세요",
    i0572: "키 값은 공백이 포함할 수 없습니다",
    i0573: "ID는 공백이 포함될 수 없습니다",
    i0574: "인텐트 선택 기능 사용",
    i0575: "인텐트 사용",
    i0576: "적합 인텐트 기준 스코어",
    i0577: "유사 인텐트 기준 스코어",
    i0578: "적합 인텐트 선택 메시지 설정",
    i0579: "적합 인텐트 선택 버튼 형식",
    i0580: "유사 인텐트 선택 메시지 설정",
    i0581: "유사 인텐트 선택 버튼 형식",
    i0582: "유사 인텐트 확인 메시지 설정",
    i0583: "유사 인텐트 확인 버튼 형식",
    i0584: "이 기능을 활성화하면 인텐트 스코어에 따라 선택지를 제공할 수 있습니다.",
    i0585: "선택지에 제공하지 않을 인텐트(구분: 쉼표(,))",
    i0586: "NLU 결과 커스터마이징(Python)",
    i0587: "Live chat",
    i0588: "상담원 연결을 하기 위한 형식입니다.",
    i0589: "카드 선택 시 동작 설정",
    i0590: "없음",
    i0591: "링크로 이동",
    i0592: "텍스트 전송",
    i0593: "다음으로 이동",
    i0594: "이전으로 돌아가기",
    i0595: "템플릿 이름은 유일한 값이어야 합니다",
    i0596: "해당 템플릿을 사용하는 씬이 있어 삭제할 수 없습니다",
    i0597: "해당 템플릿을 사용하는 템플릿이 있어 삭제할 수 없습니다",
    i0598: "Emotion",
    i0599: "Happy",
    i0600: "Hurt",
    i0601: "Sad",
    i0602: "Anxious",
    i0603: "Embarrassed",
    i0604: "Angry",
    i0605: "아바타 이미지 설정",
    i0606: "팀업",
    i0607: "팀업 메신저와 연동하여 챗봇을 서비스할 수 있습니다",
    i0608: "팀업 연동",
    i0609: "팀업 메신저 연동 활성화",
    i0610: "구축형",
    i0611: "시나리오 이름, 설명, 작성자 검색",
    i0612: "시나리오 업로드",
    i0613: "File",
    i0614: "파일 다운로드 메시지 입니다. 설명, 파일 다운로드 버튼 이름을 설정할 수 있습니다.",
    i0615: "파일 다운로드 데이터 설정",
    i0616: "파일을 선택하거나 파일 URL을 입력하세요",
    i0617: "첨부한 파일에대한 설명을 입력하세요",
    i0618: "파일 다운로드 버튼 라벨을 입력하세요",
    i0619: "메시지에서 HTML 태그 제거(성능 저하)",
    i0620: "LGUplus RCS",
    i0621: "LGUplus Rich Communication Service로 연동하여 SMS로 챗봇을 제공할 수 있습니다",
    i0622: "RCS 연동",
    i0623: "RCS 연동 활성화",
    i0624: "다른 시나리오로 동작",
    i0625: "시나리오 선택",
    i0626: "메신저",
    i0627: "채팅 상담",
    i0628: "스펙트라의 eNomix Engager와 연동하여 채팅상담을 진행할 수 있습니다",
    i0629: "eNomix 연동",
    i0630: "eNomix 연동 활성화",
    i0631: "LiveChat 연동",
    i0632: "LiveChat 연동 활성화",
    i0633: "LiveChat과 연동하여 채팅상담을 진행할 수 있습니다",
    i0634: "Disable Keyboard",
    i0635: "Disable keyboard user input",
    i0636: "시나리오 통계 설정",
    i0637: "문의 유형 통계 제외 씬 설정",
    i0638: "네이버톡톡",
    i0639: "네이버톡톡으로 챗봇을 서비스할 수 있습니다.",
    i0640: "네이버톡톡 연동",
    i0641: "네이버톡톡 연동 활성화",
    i0642: "네이버톡톡과 챗봇을 연동하려면 체크하세요",
    i0643: "이벤트 받을 URL",
    i0644: "페이스북 메신저",
    i0645: "페이스북 페이지에 챗봇을 서비스 할 수 있습니다",
    i0646: "페이스북 연동",
    i0647: "페이스북 연동 활성화",
    i0648: "인스타그램",
    i0649: "인스타그램에서 챗봇을 서비스할 수 있습니다",
    i0650: "인스타그램 연동",
    i0651: "인스타그램 연동 활성화",
    i0652: "팀업 메신저와 챗봇을 연동하려면 체크하세요. 체크를 해제하면 팀업 메신저에서 챗봇이 동작하지 않습니다.",
    i0653: "eNomix와 챗봇을 연동하려면 체크하세요. 체크를 해제하면 eNomix와 상담이 진행되지 않습니다.",
    i0654: "LiveChat과 챗봇을 연동하려면 체크하세요. 체크를 해제하면 LiveChat과 상담이 진행되지 않습니다.",
    i0655: "LGUplus RCS와 챗봇을 연동하려면 체크하세요. 체크를 해제하면 LGUplus RCS에서 챗봇이 동작하지 않습니다.",
    i0656: "UiPath와 챗봇을 연동하려면 체크하세요.",
    i0657: "페이스북과 챗봇을 연동하려면 체크하세요",
    i0658: "인스타그램과 챗봇을 연동하려면 체크하세요",
    i0659: "배너 표시 URL 화이트 리스트(쉼표로 구분)",
    i0660: "올바른 카카오톡 Share URL을 입력하세요. 예) http://pf.kakao.com/xxxxxx (카카오톡 비즈니스 관리자 센터에서 확인하실 수 있습니다. https://business.kakao.com)",
    i0661: "올바른 페이스북 Share URL을 입력하세요. 예) https://m.me/page_name",
    i0662: "올바른 인스타그램 Share URL을 입력하세요. 예) https://www.instagram.com/instagram_account",
    i0663: "올바른 네이버톡톡 Share URL을 입력하세요. 예) http://naver.me/xxxxxx",
    i0664: "네이버톡톡 링크를 가져오려면 QR코드 이미지(PNG)를 여기에 업로드해주세요.\n\n[QR코드 다운로드 방법]\n네이버톡톡 파트너센터 > 설정 > 톡톡 배너/QR코드 다운 > 우측 하단 '다운받기' > PNG로 저장",
    i0665: "라인",
    i0666: "라인으로 챗봇을 서비스할 수 있습니다.",
    i0667: "라인 연동 활성화",
    i0668: "라인 Webhook Url",
    i0669: "라인 연동",
    i0670: "라인 연동 방법",
    i0671: "1. provider를 생성한 뒤 선택합니다.\n2. channel생성을 시작하여 messaging api를 선택합니다.",
    i0672: "채널에 들어가 Messaging API 탭을 선택하고 WebHook Url 주소를 추가합니다.\n라인 Webhook Url을 복사 붙여넣기 하세요.",
    i0673: "Line Official Account features의 Auto-reply messages와 Greeting messages를 Disabled로 바꿔주어야합니다.\nAuto-reply messages의 Edit 버튼을 클릭하여 설정창으로 들어갑니다.",
    i0674: "Channel access token을 설정해줍니다.",
    i0675: "Webhook 설정",
    i0676: "채널 답변 특성 설정",
    i0677: "Channel access token를 생성해줍니다.",
    i0678: "표시된 것과 같이 설정해줍니다.",
    i0679: "라인과 챗봇을 연동하려면 체크하세요. 체크를 해제하면 라인에서 챗봇이 동작하지 않습니다.",
    i0680: "인덱스 화면 사용",
    i0681: "올바른 라인 Share URL을 입력하세요. 예) https://line.me/R",
    i0682: "Share URL 가이드",
    i0683: "Share URL을 설정하면 WebChat에 메신저로 바로가기 아이콘을 노출할 수 있습니다.",
    i0684: "1. 페이지 관리하기에서 받은 메시지함 클릭",
    i0685: "2. 받은 메시지함에서 톱니바퀴 클릭",
    i0686: "3. Messaging URL 복사 후 Share URL에 등록",
    i0687: "1. 채널 관리자 센터 접속",
    i0688: "Share URL 설정 방법",
    i0689: "2. 채널 홍보 탭 클릭\n3. 채팅 URL 복사",
    i0690: "Share URL 가이드",
    i0691: "네이버톡톡 연동 가이드",
    i0692: "1. 네이버톡톡 파트너센터 접속",
    i0693: "2. 톡톡 계정 생성 후 계정 관리 페이지 접속\n3. 개발자도구 탭의 챗봇API 설정 클릭",
    i0694: "네이버톡톡 연동 설정",
    i0695: "수신 이벤트 설정",
    i0696: "1. Webhook URL 설정\n2. 수신할 이벤트 설정\n3. Authorization 생성 후 CM에 등록",
    i0697: "수신할 이벤트는 end, open, leave, friend, echo 체크",
    i0698: "인스타그램은 채팅으로 바로가기 URL을 제공하지 않습니다.\n인스타그램 계정 URL을 입력해주세요.\n\nex) https://www.instagram.com/계정명",
    i0699: "로그인 사용자 통계",
    i0700: "로그인 사용자 통계에서 추가 노출 항목을 설정할 수 있습니다",
    i0701: "키 값 예) extra_info.nickname",
    i0702: "타이틀명 예) 닉네임",
    i0703: "대화 이력 추가 항목",
    i0704: "대화 이력에 노출되는 추가 항목을 설정할 수 있습니다.",
    i0705: "키 값 예) context.global.login_user.id",
    i0706: "타이틀명 예) 아이디",
    i0707: "대화 이력 다운로드 추가 항목",
    i0708: "Microsoft Azure Bot Services",
    i0709: "Microsoft Azure Bot Services를 통해 추가적인 외부 메신저와 연결할 수 있습니다.\n(Teams, Alexa, Slack, Telegram등)",
    i0711: "Microsoft Azure Bot Services 연동",
    i0712: "Microsoft Azure Bot Services 연동 활성화",
    i0713: "Webhook URL",
    i0714: "올바른 애저 Share URL을 입력하세요. 예) https://teams.microsoft.com/",
    i0715: "애저 연동 가이드",
    i0716: "Azure Bot 생성",
    i0717: "에 접속하여 Bot Services > 만들기 > 맨 아래 '추가 로드' > Azure Bot을 선택하여 생성합니다.",
    i0718: "Webhook URL 등록",
    i0719: "Webhook URL을 복사하여 끝점 메시지 보내기에 입력합니다.",
    i0720: "1. Microsoft App ID를 복사하여 위 Microsoft App ID 항목에 입력합니다.\n2. 관리를 클릭하여 비밀번호 생성창으로 접속합니다.",
    i0721: "3. 새 클라이언트 암호를 클릭하여 비밀번호를 생성하고\n값에 복사하기 버튼을 클릭하여 복사한 뒤 위의 Microsoft Password 항목에 입력합니다.",
    i0722: "팀즈 연동하기",
    i0723: "1. Microsoft Teams 채널을 클릭합니다.",
    i0724: "2. Microsoft Teams 설정 후에 저장을 클릭합니다.(기본값으로 저장해도 무관)",
    i0725: "Microsoft App 정보 등록",
    i0726: "슬랙 연동",
    i0727: "슬랙 연동 활성화",
    i0728: "슬랙과 챗봇을 연동하려면 체크하세요. 체크를 해제하면 슬랙에서 챗봇이 동작하지 않습니다.",
    i0729: "슬랙",
    i0730: "슬랙 연동 가이드",
    i0731: "슬랙 app 생성",
    i0732: "에 접속하여 create new app으로 app을 생성합니다.\n그리고 생성된 app의 설정화면으로 이동합니다.",
    i0733: "상호작용 컴포넌트 웹 훅 등록",
    i0734: "Slack Webhook URL을 복사하여 상호작용 컴포넌트의 Request URL에 등록해줍니다.",
    i0735: "Oauth Redirect URL 등록",
    i0736: "Slack Oauth Redirect URL을 복사하여 Redirect URL에 추가해줍니다.",
    i0737: "이벤트 구독",
    i0738: "Bot Token Scope 등록",
    i0739: "Bot Token Scope에 chat:write를 추가해줍니다.\n이를 추가해야 챗봇이 유저에게 메시지를 보낼 수 있습니다.",
    i0740: "이벤트를 수신하기 위해 Slack Webhook URL을 복사하여 Request URL에 등록해줍니다.\n이벤트는 유저가 보낸 메시지를 봇이 수신할 수 있도록 message.im을 등록합니다.",
    i0741: "워크스페이스에 앱 설치",
    i0742: "install 버튼을 클릭하여 워크스페이스에 앱을 설치합니다.",
    i0743: "연동 정보 입력",
    i0744: "install 후 보여지는 bot User OAuth Token을 Conversation Manager의 Bot User OAuth Token에 입력합니다.",
    i0745: "Client ID와 Client Secret 또한 복사하여 Conversation Maker에 입력해줍니다.",
    i0746: "슬랙 워크스페이스에서 챗봇을 서비스할 수 있습니다.",
    i0747: "메시지 탭 활성화",
    i0748: "1. App Home > Your App’s Presence in Slack 에서 Always Show My Bot as Online을 활성화해줍니다.\n2. App Home > Show Tab에서 Message Tab을 활성화해줍니다.\n3. Allow users to send...(이하 생략) 체크 박스를 체크해줍니다.\n이를 통해 앱과 직접 메시지를 주고받을 수 있습니다",
    i0749: "Zendesk",
    i0750: "Zendesk와 연동하여 채팅상담을 진행할 수 있습니다.",
    i0751: "Zendesk 연동",
    i0752: "Zendesk 연동 활성화",
    i0753: "Zendesk 연동 방법",
    i0754: "Zendesk 웹사이트에 접속하여 채널을 생성합니다.",
    i0755: "API Token 생성",
    i0756: "웹훅 생성하기",
    i0757: "웹훅 생성 페이지에 접속합니다.",
    i0758: "웹훅 만들기 버튼을 클릭한 뒤\n아래의 두 가지 웹훅을 만들어줍니다.\n1.상담사 메시지 웹훅\n2.상담 종료 웹훅",
    i0759: "트리거 만들기",
    i0760: "트리거 생성 페이지에 접속합니다.",
    i0761: "이전 단계에서 생성한 웹훅을 사용하는 두 가지 트리거를 만들어줍니다.\n1.상담사 메시지 트리거\n2.상담 종료 트리거",
    i0762: "웹훅 생성하기",
    i0763: "에 접속하여 api 토큰을 생성하여 conversation maker의 API Token칸에 입력해줍니다.\n",
    i0764: "2. 생성된 채널에 접속하여 subdomain을 확인하고 conversation manager의 subdomain칸에 입력합니다.\n(예시 - https://{{subdomain}}.zendesk.com)",
    i0765: "1. 채널을 생성한 아이디의 이메일을 conversation maeker의 Zendesk Email 칸에 입력합니다.",
    i0766: "상담사 메시지 트리거에는 웹훅의 JSON 본문에 Message Webhook Body를 복사/붙여넣기 해줍니다.",
    i0767: "상담 종료 트리거에는 웹훅의 JSON 본문에 End Webhook Body를 복사/붙여넣기 해줍니다.",
    i0768: "SwingChat Message",
    i0769: "SwingChat Message의 메시지 전송 기능을 사용할 수 있습니다.",
    i0770: "SwingChat Message 연동",
    i0771: "SwingChat Message 연동 활성화",
    i0772: "Zendesk와 챗봇을 연동하려면 체크하세요. 체크를 해제하면 Zendesk에서 챗봇이 동작하지 않습니다.",
    i0773: "SwingChat Message와 챗봇을 연동하려면 체크하세요. 체크를 해제하면 SwingChat Message의 기능을 사용할 수 없습니다.",
    i0774: "상담 종료 트리거 만들기",
    i0775: "업무 시간 외 상담 거절 트리거 만들기",
    i0776: "위 링크에 접속하여 상담 종료 트리거를 생성합니다.",
    i0777: "위 링크에 접속하여 업무 시간 외 상담 거절 트리거를 생성합니다.",
    i0778: "위 링크에 접속하여 업무 시간으로 설정할 시간을 일정으로 생성합니다.",
    i0779: "웹훅의 JSON 본문에 Counseling Reject Body를 복사/붙여넣기 해줍니다.",
    i0780: "FAQ 관리",
    i0781: "카테고리",
    i0782: "카테고리 추가",
    i0783: "카테고리 삭제",
    i0784: "80자 이내로 입력해주세요",
    i0785: "템플릿으로 이동하여 편집",
    i0786: "복사 완료",
    i0787: "Token을 재발급하면 기존에 발급된 토큰을 사용하는 Open API가 정지될 수 있습니다.",
    i0788: "Token을 삭제하면 사용중인 Open API를 사용할 수 없습니다.",
    i0789: "이미 사본이 존재합니다.",
    i0790: "테스트 실패",
    i0791: "Add Scenario",
    i0792: "Add Scenario Settings",
    i0793: "Add Scenario Templates",
    i0794: "챗봇 연동 설정 추가",
    i0795: "Remove Scenario",
    i0796: "Remove Scenario Settings",
    i0797: "Remove Scenario Templates",
    i0798: "챗봇 연동 설정 삭제",
};


import React, { useEffect } from "react";
import axios from "axios";
import { WebChat, DEFAULT_WEBCHAT_THEME } from "@42maru/webchat";
import ReactGA from "react-ga";
import Favicon from "react-favicon";
import { API_HOST, DISABLE_GA, SOCKET_HOST, WEBCHAT_SOCKET_PATH } from "./variables";

interface WebChatAppProps {
    scenarioId: string;
    conversationId?: string | string[] | null;
    isTest?: boolean;
    params?: any;
}

const readPublishData = (scenarioId: string) => {
    return axios
        .get(`${API_HOST}/scenario/${scenarioId}/publisher`)
        .then((response) => Promise.resolve(response.data))
        .catch((error) => Promise.reject(error));
};

const WebChatApp: React.FC<WebChatAppProps> = ({ scenarioId, conversationId, params }) => {
    const [publishData, setPublishData] = React.useState<any>();
    const [initialized, setInitialized] = React.useState<boolean>(false);
    const [dimensions, setDimensions] = React.useState({
        height: window.innerHeight,
        width: window.innerWidth
    });

    useEffect(() => {
        const handleResize = () => {
            let width = window.innerWidth;
            let height = window.innerHeight;

            setDimensions({
                height: height,
                width: width
            });
        };

        window.addEventListener("resize", handleResize);

        return () => {
            window.removeEventListener("resize", handleResize);
        };
    }, [scenarioId]);

    useEffect(() => {
        readPublishData(scenarioId)
            .then((response) => {
                setPublishData(response.data);
                setInitialized(true);
            })
            .catch(() => {
                setInitialized(true);
            });
    }, [scenarioId]);

    if (!initialized) {
        return <div></div>;
    }
    const theme = (publishData && publishData.webchat_theme) || DEFAULT_WEBCHAT_THEME;
    document.title = theme.title || "WebChat";

    if (!DISABLE_GA) {
        ReactGA.pageview(`${window.location.hostname}/${window.location.pathname}${window.location.search}`);
    }
    return (
        <>
            <Favicon url={theme.faviconUrl || ""} />
            <WebChat
                clientType="chat_only"
                footerType="cm"
                connectionConfig={{
                    apiHost: `${API_HOST}`,
                    socketHost: `${SOCKET_HOST}`,
                    socketPath: `${WEBCHAT_SOCKET_PATH}`,
                    method: "socket",
                    test: false
                }}
                style={{
                    width: dimensions.width || "100vw",
                    height: dimensions.height || "100vh"
                }}
                scenarioId={scenarioId}
                theme={theme}
                previousConversationId={conversationId as string}
                params={params}
            />
        </>
    );
};

export default WebChatApp;

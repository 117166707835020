import axios from "axios";
import { API_HOST } from "../variables";

axios.defaults.withCredentials = true;

export const checkAuthorization = () => {
    return axios
        .get(`${API_HOST}/auth/authorization`)
        .then((response) => Promise.resolve(response.data))
        .catch((error) => Promise.reject(error));
};

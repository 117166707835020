import { action, makeObservable, observable, runInAction } from "mobx";
import {Scenario, Properties, KeyValueItem, FaqItem} from "../model/Scenario";
import * as scenarioApi from "../api/scenarioApi";
import { configure } from "mobx";

configure({
    useProxies: "never"
});
class ScenarioStore {
    @observable scenario?: Scenario;
    @observable scenarios: Scenario[] = [];
    @observable constants: KeyValueItem[] = [];
    @observable faq: FaqItem[] = [];
    @observable permissions: { [key: string]: string[] } = {};
    @observable autocompletes: {
        intent: string[];
        entity: { [key: string]: string[] };
        variable: { user: string[]; system: string[]; constants: string[] };
    } = { intent: [], entity: {}, variable: { user: [], system: [], constants: [] } };
    @observable isPendingGetPermissions: boolean = false;
    isPendingScenarioUpdate: boolean = false;

    constructor() {
        makeObservable(this);
    }

    find = async () => {
        try {
            this.isPendingScenarioUpdate = true;
            const response = await scenarioApi.find();
            runInAction(() => {
                this.scenarios = response.data;
                this.isPendingScenarioUpdate = false;
            });
            return Promise.resolve(response);
        } catch (e) {
            this.isPendingScenarioUpdate = false;
            return Promise.reject(e);
        }
    };

    @action
    findForMenu = () => {
        if (this.isPendingScenarioUpdate || this.scenarios.length > 0) {
            return;
        }
        this.isPendingScenarioUpdate = true;
        scenarioApi.find()
            .then((response) => {
                runInAction(() => {
                    this.isPendingScenarioUpdate = false;
                    this.scenarios = response.data;
                });
            })
            .catch((e) => {
                this.isPendingScenarioUpdate = false;
            });
    };

    create = async (name: string, description: string) => {
        try {
            const response = await scenarioApi.create(name, description);
            await this.find();
            return Promise.resolve(response.data);
        } catch (e) {
            return Promise.reject(e);
        }
    };

    read = async (scenarioId: string) => {
        try {
            const response = await scenarioApi.read(scenarioId);
            runInAction(() => {
                this.scenario = response.data;
                this.constants = [];
                this.faq = [];
            });
            Object.keys(this.scenario!.constants || {}).forEach(
                action((key, index) => {
                    this.constants.push({
                        key,
                        value: Object.values(this.scenario!.constants || {})[index] as string
                    });
                })
            );
            this.faq = this.scenario!.faq || []

            return Promise.resolve(response.data);
        } catch (e) {
            return Promise.reject(e);
        }
    };

    update = async (scenarioId: string, scenario: Partial<Scenario>) => {
        try {
            const response = await scenarioApi.update(scenarioId, scenario);
            runInAction(() => {
                this.scenario = response.data;
            });

            return Promise.resolve(response.data);
        } catch (e) {
            return Promise.reject(e);
        }
    };

    deleteScenario = async (scenarioId: string) => {
        try {
            await scenarioApi.deleteScenario(scenarioId);
        } catch (e) {
            return Promise.reject(e);
        }
    };

    @action
    setProperties = (properties: Partial<Properties>) => {
        if (this.scenario) {
            this.scenario.properties = { ...this.scenario.properties, ...properties };
        }
    };

    @action
    setScenario = (scenario: Scenario) => {
        this.scenario = { ...this.scenario, ...scenario };
    };

    @action
    clear = () => {
        this.scenario = undefined;
        this.constants = [];
        this.faq = [];
        this.autocompletes = { intent: [], entity: {}, variable: { user: [], system: [], constants: [] } };
    };

    getScenarioEditorAutocompletes = async (scenarioId: string) => {
        try {
            const response = await scenarioApi.getScenarioAutocompletes(scenarioId);
            runInAction(() => {
                this.autocompletes = response.data;
            });
            return Promise.resolve(response.data);
        } catch (e) {
            return Promise.reject(e);
        }
    };

    @action
    setConstants = (constants: KeyValueItem[]) => {
        this.constants = constants;
    }

    @action
    setFaq = (faq: FaqItem[]) => {
        this.faq = faq
    }

    loadPermissions = (scenarioId: string) => {
        if (this.isPendingGetPermissions) {
            return;
        }
        this.isPendingGetPermissions = true;
        scenarioApi.getScenarioPermissions(scenarioId)
            .then((response) => {
                runInAction(() => {
                    this.isPendingGetPermissions = false;
                    if (Array.isArray(response)) {
                        this.permissions[scenarioId] = response;
                    } else {
                        this.permissions[scenarioId] = ["view:*"];
                    }
                });
            })
            .catch(() => {
                this.isPendingGetPermissions = false;
                this.permissions[scenarioId] = [];
            });
    };
}

const store = new ScenarioStore();

export default store;

export const API_HOST = process.env.REACT_APP_API_HOST;
export const SOCKET_HOST = process.env.REACT_APP_SOCKET_HOST;
export const SOCKET_PATH = process.env.REACT_APP_SOCKET_PATH;
export const WEBCHAT_SOCKET_PATH = process.env.REACT_APP_WEBCHAT_SOCKET_PATH;
export const WEB_CHAT_APP_URL = process.env.REACT_APP_WEB_CHAT_APP_URL;
export const PRODUCT = process.env.REACT_APP_PRODUCT;
export const WEBCHAT_HOST = process.env.REACT_APP_WEBCHAT_HOST;
export const API_PROXY_HOST = process.env.REACT_APP_API_PROXY_HOST;

export const THEME = process.env.REACT_APP_THEME;
export const KEEPER_HOST = process.env.REACT_APP_KEEPER_HOST;
export const KEEPER_API_HOST = process.env.REACT_APP_KEEPER_API_HOST;
export const KEEPER_CLIENT_ID = process.env.REACT_APP_KEEPER_CLIENT_ID;
export const KEEPER_ENVIRONMENT = process.env.REACT_APP_KEEPER_ENVIRONMENT;
export const FACEBOOK_APP_ID = process.env.REACT_APP_FACEBOOK_APP_ID;
export const FACEBOOK_APP_SECRET = process.env.REACT_APP_FACEBOOK_APP_SECRET;

export const GA_TRACKING_KEY = process.env.REACT_APP_GA_TRACKING_KEY;
export const DISABLE_GA = process.env.REACT_APP_DISABLE_GA === "true";
export const DISABLE_BUGSNAG = process.env.REACT_APP_DISABLE_BUGSNAG === "true";
export const DISABLE_INTEGRATION_FACEBOOK = process.env.REACT_APP_DISABLE_INTEGRATION_FACEBOOK === "true";
export const DISABLE_INTEGRATION_INSTAGRAM = process.env.REACT_APP_DISABLE_INTEGRATION_INSTAGRAM === "true";
export const DISABLE_INTEGRATION_KAKAOTALK = process.env.REACT_APP_DISABLE_INTEGRATION_KAKAOTALK === "true";
export const DISABLE_INTEGRATION_NAVERTALK = process.env.REACT_APP_DISABLE_INTEGRATION_NAVERTALK === "true";
export const DISABLE_INTEGRATION_TEAMUP = process.env.REACT_APP_DISABLE_INTEGRATION_TEAMUP === "true";
export const DISABLE_INTEGRATION_UPLUSRCS = process.env.REACT_APP_DISABLE_INTEGRATION_UPLUSRCS === "true";
export const DISABLE_INTEGRATION_ENOMIX = process.env.REACT_APP_DISABLE_INTEGRATION_ENOMIX === "true";
export const DISABLE_INTEGRATION_LIVECHAT = process.env.REACT_APP_DISABLE_INTEGRATION_LIVECHAT === "true";
export const DISABLE_INTEGRATION_UIPATH = process.env.REACT_APP_DISABLE_INTEGRATION_UIPATH === "true";
export const DISABLE_INTEGRATION_LINE = process.env.REACT_APP_DISABLE_INTEGRATION_LINE === "true";
export const DISABLE_INTEGRATION_AZURE = process.env.REACT_APP_DISABLE_INTEGRATION_AZURE === "true";
export const DISABLE_INTEGRATION_SLACK = process.env.REACT_APP_DISABLE_INTEGRATION_SLACK === "true";
export const DISABLE_INTEGRATION_ZENDESK = process.env.REACT_APP_DISABLE_INTEGRATION_ZENDESK === "true";
export const DISABLE_INTEGRATION_SWINGCHAT_MESSAGE = process.env.REACT_APP_DISABLE_INTEGRATION_SWINGCHAT_MESSAGE === "true";
export const SHOW_LOGIN_USER_ANALYTICS = process.env.REACT_APP_SHOW_LOGIN_USER_ANALYTICS === "true";
export const CM_CTX = process.env.REACT_APP_CM_CTX;
